<template>
  <base-popup
      :popup-model="show"
      title="Order Details"
      :valid="!valid"
      :width="56"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container class="ma-2">
        <v-card-subtitle class="ma-0">Cart Invoice</v-card-subtitle>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="orderData"
                class="elevation-1 mt-0"
                hide-default-footer
            >
              <template v-slot:no-data>
                Empty Cart!
              </template>
              <template v-slot:item.siteDetails="{ item }">
                <p class="ma-0">
                  Publisher Site ID #{{ item.site.site_id }} Guest Post Service
                </p>
                <span class="ma-0">Website: <v-chip
                    class="ma-2"
                    color="primary"
                    link
                    @click.prevent.stop="openSite(item.site.site_url)"
                >
                  {{ item.site.site_url }}
                </v-chip></span>
              </template>
              <template v-slot:item.price="{ item }">
                <div class="qty-input-field">${{ item.price }}</div>
              </template>
              <template v-slot:item.extraServices="{ item }">
                <p class="ma-0">{{ getServicesDetails(item.id) }}</p>
              </template>
              <template v-slot:item.serviceTotal="{ item }">
                <p class="ma-0">${{ getServicesTotal(item.id) }}</p>
              </template>
              <template v-slot:item.total="{ item }">
                $ {{subTotal({total: item.price, id: item.id})}}
              </template>
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td
                      colspan="3"
                      style="text-align: right; font-weight: 500; font-size: 20px;"
                  >
                    Grand Total:
                  </td>
                  <td style="text-align: right">${{item.total}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td
                      colspan="2"
                      style="text-align: right; font-weight: 500; font-size: 20px;"
                  >
                    Order Status
                  </td>
                  <td
                      colspan="2"
                      style="text-align: right; font-weight: 500; font-size: 20px;"
                  >
                    <v-select
                        class="details"
                        v-model="currentItem.status"
                        :items="orderStatus"
                        dense
                        outlined
                    ></v-select>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";

export default {
  name: 'SitePopup',
  components: {BasePopup},
  mixins: [PopupMixin],
  data() {
    return {
      headers: [
        {
          text: "Site Details",
          value: "siteDetails",
        },
        {
          text: "Price",
          value: "price",
          align: "right",
        },
        {
          text: "Service Details",
          value: "extraServices",
        },
        {
          text: "Service Total",
          value: "serviceTotal",
          align: "right",
        },
        {
          text: "Sub Total",
          value: "total",
          align: "right",
        }
      ],
      orderStatus: ['pending', 'accepted', 'rejected', 'completed']
    }
  },
  computed: {
    orderData() {
      return this.currentItem && this.currentItem.orderDetails ? this.currentItem.orderDetails : []
    }
  },
  methods: {
    getServicesDetails(id) {
      return this.currentItem.oDExtraFacilities.filter((item) => item.o_details_id === id)
          .map((data) => {
            return `${data.name}: $${data.price}`;
          })
          .join(", ");
    },
    getServicesTotal(id) {
      return this.currentItem.oDExtraFacilities.filter((item) => item.o_details_id === id)
          .reduce(function(subTotal, item) {
            return Number.parseFloat(subTotal + Number(item.price));
          }, 0);
    },
    subTotal({total, id}) {
      const price = Number(total);
      const extraFacilityTotal = Number(this.getServicesTotal(id));

      return Number(price + extraFacilityTotal).toFixed(2);
    },
    openSite(site) {
      window.open(`http://www.${site}`, '_blank');
    }
  }
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0 !important;;
  margin-bottom: 0 !important;
  display: none !important;;
}
</style>
