<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        :popup-component="popupComponent"
        sort="order_id"
        :descending="true"
        title="Order List"
        icon="mdi-briefcase"
        stateendpoint="order.orders"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButtons"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import SitePopup from "../components/popups/SitePopup";
import OrderPopup from "../components/popups/OrderPopup";

export default {
  name: 'Orders',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Orders List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, SitePopup},
  data() {
    return {
      headers: [
        {
          text: "Order ID",
          value: "order_id",
        },
        {
          text: "Order By",
          value: "user",
        },
        {
          text: "Order Total",
          value: "total",
          align: "right",
        },
        {
          text: "Order Date",
          value: "date",
          align: "right",
        },
        {
          text: 'Status',
          value: 'orderStatus',
          align: 'center'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        { value: "order_id" },
        { value: "user" },
        { value: "total" },
        { value: "orderStatus" },
        { value: "date" },
        { value: "action" },
      ],
      actions: {
        load: "loadOrders",
        update: "updateOrder"
      },
      actionButtons: [
        {
          category: "update",
          icon: "mdi-eye",
          color: "success",
          text: "Change Status",
          clickHandler: "editItem"
        },
        {
          category: "file",
          icon: "mdi-file-document-multiple",
          color: "success",
          text: "Files",
          clickHandler: "fileHandler"
        }
      ]
    }
  },
  computed: {
    popupComponent() {
      return OrderPopup;
    }
  }
}
</script>
